import React, {
    useEffect,
    useContext,
    useCallback,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    DateField,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useDataRefresh } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { DateUtil } from 'gw-portals-util-js';
import { useValidation } from 'gw-portals-validation-react';
import messages from './AcceptWorkActionPopup.messages';

const today = DateUtil.currentDateAtMidnight();

function AcceptWorkActionPopup(props) {
    const translator = useContext(TranslatorContext);
    const {
        serviceRequest,
        viewModelService,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [actionVM, updateActionVM] = useState();
    const [dateBoardClicked, updateDateBoardClicked] = useState(false);
    const [isVMInitialized, setVMInitialized] = useState(false);
    const { isComponentValid, registerComponentValidation } = useValidation('AcceptWorkActionPopup');
    const { refreshData } = useDataRefresh();

    const writeValue = useCallback(
        (value, path) => {
            _.set(actionVM, `${path}.value`, value);
            updateActionVM(actionVM);
            updateDateBoardClicked(true);
            refreshData();
        },
        [actionVM, refreshData, dateBoardClicked]
    );

    const handleDefaultAction = useCallback(() => {
        if (isComponentValid) {
            if (!dateBoardClicked) {
                const currentdate = new Date();
                _.set(actionVM, `completionDate.value`, DateUtil.getYear(currentdate) + "-" + ('0' + (currentdate.getMonth() + 1)).slice(-2) + "-" + ('0' + (DateUtil.getDate(currentdate))).slice(-2));
                updateActionVM(actionVM);
            };
            onResolve(actionVM.value);
        }
    }, [actionVM, isComponentValid, onResolve, dateBoardClicked]);

    const validateForm = useCallback(() => {
        if (isVMInitialized) {
            return actionVM.aspects.valid && actionVM.aspects.subtreeValid;
        }
        return false;
    }, [actionVM, isVMInitialized]);

    useEffect(() => {
        if (isVMInitialized) {
            registerComponentValidation(validateForm);
        }
    }, [actionVM, isVMInitialized, registerComponentValidation, validateForm]);

    useEffect(() => {
        const currentDate = new Date();
        updateActionVM(
            viewModelService.create({
                serviceRequestNumber: serviceRequest.serviceRequestNumber,
                completionDate: currentDate,
                completionDate_PAExt: {year: DateUtil.getYear(currentDate), month: DateUtil.getMonth(currentDate), day: DateUtil.getDate(currentDate) },
                RequestKind_PAExt: serviceRequest.requestKind
            }, 'cc', 'edge.capabilities.servicerequest.dto.ServiceRequestAcceptWorkDTO')
        );
        setVMInitialized(true);
        // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (_.isEmpty(actionVM)) return null;

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={translator(messages.actionTitleAcceptWork)} />
            <ModalBody id="modalAcceptWork">
                <DateField
                    id="completionDate"
                    path="completionDate"
                    label={serviceRequest.requestKind === 'serviceonly' ? translator(messages.actionServiceCompletionDate) : translator(messages.actionCompletionDate)}
                    showTime={false}
                    format="short"
                    dataType="string"
                    minDate={today}
                    value={_.get(actionVM, 'completionDate.value')}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="secondary">
                    {translator(messages.actionButtonCancel)}
                </Button>
                <Button
                    onClick={handleDefaultAction}
                    disabled={!isComponentValid}
                >
                    {translator(messages.actionButtonAccept)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

AcceptWorkActionPopup.propTypes = {
    serviceRequest: PropTypes.shape([]).isRequired,
    viewModelService: PropTypes.shape([]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default AcceptWorkActionPopup;
