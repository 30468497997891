import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import messages from './CreateNewMessagePopup.messages';
import metadata from './CreateNewMessagePopup.metadata.json5';

function CreateNewMessagePopup(props) {
    const [messageVM, setMessageVM] = useState({});
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();
    const { isComponentValid, onValidate } = useValidation('CreateNewMessagePopup');
    const {
        isOpen,
        onReject,
        onResolve,
        onAfterClose,
        serviceRequestNumber,
        authHeader
    } = props;


    useEffect(() => {
        setMessageVM(
            viewModelService.create(
                {
                    type: 'question'
                },
                'cc',
                'edge.capabilities.servicerequest.message.dto.ServiceRequestMessageDTO'
            )
        );
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSend = useCallback(async () => {
        messageVM.sendDate.value = new Date();
        const newMessage = await ServiceRequestService.createMessage(
            serviceRequestNumber,
            messageVM.value,
            authHeader
        );
        onResolve(newMessage);
    }, [authHeader, messageVM, onResolve, serviceRequestNumber]);

    const handleClose = useCallback(() => {
        onReject();
    }, [onReject]);

    const handleMessageVMChange = useCallback((newModel) => {
        setMessageVM(newModel);
        refreshData();
    }, [refreshData]);


    if (_.isEmpty(messageVM)) {
        return null;
    }

    const overrides = {
        '@field': {
            showOptional: true
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate
        }
    };

    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} onAfterClose={onAfterClose}>
            <ModalHeader title={translator(messages.addMessageTitle)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent.content}
                    model={messageVM}
                    onValidationChange={onValidate}
                    onModelChange={handleMessageVMChange}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    type="tertiary"
                    onClick={handleClose}
                >
                    {translator(messages.cancel)}
                </Button>
                <Button
                    onClick={handleSend}
                    disabled={!isComponentValid}
                >
                    {translator(messages.send)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

CreateNewMessagePopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    onAfterClose: PropTypes.func.isRequired,
    serviceRequestNumber: PropTypes.string.isRequired,
    authHeader: PropTypes.string,
    messageVM: PropTypes.shape({
        type: PropTypes.shape({
            value: PropTypes.shape({})
        }),
        subject: PropTypes.shape({}),
        sendDate: PropTypes.shape({
            value: PropTypes.shape({})
        }),
        body: PropTypes.shape({}),
        value: PropTypes.shape({})
    })
};

CreateNewMessagePopup.defaultProps = {
    messageVM: undefined,
    authHeader: undefined
};

export default CreateNewMessagePopup;
