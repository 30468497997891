import { defineMessages } from 'react-intl';

export default defineMessages({
    addInvoiceTitle: {
        id: 'servicerequest.views.add-invoice.title.Add Invoice',
        defaultMessage: 'Add Invoice'
    },
    reviseInvoiceTitle: {
        id: 'servicerequest.views.add-invoice.title.Revise Invoice',
        defaultMessage: 'Revise Invoice'
    },
    totalAmount: {
        id: 'servicerequest.views.add-invoice.Total Amount',
        defaultMessage: 'Total Amount'
    },
    referenceNumber: {
        id: 'servicerequest.views.add-invoice.Reference Number',
        defaultMessage: 'Reference Number'
    },
    description: {
        id: 'servicerequest.views.add-invoice.Description',
        defaultMessage: 'Description'
    },
    error: {
        id: 'servicerequest.views.add-revise-invoice.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'servicerequest.views.add-revise-invoice.Something went wrong during this process.',
        defaultMessage: 'Something went wrong during this process.'
    },
    cancel: {
        id: 'servicerequest.views.add-invoice.buttons.Cancel',
        defaultMessage: 'Cancel'
    },
    add: {
        id: 'servicerequest.views.add-invoice.button.Add',
        defaultMessage: 'Add'
    },
    fileSizeErrorTitle: {
        id: 'servicerequest.views.add-invoice.Large File Size Error',
        defaultMessage: 'File Upload'
    },
    fileTypeValidationMessage: {
        id: 'servicerequest.views.add-invoice.File Type Validation Error Message',
        defaultMessage: 'File types exe, jar, and zip are not allowed.'
    }
});
