import React, {
    useState, useEffect, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import { Link } from 'gw-components-platform-react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext, FormattedDate } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import { useAuthentication } from 'gw-digital-auth-react';
import {
    Loader,
} from '@jutro/components';

import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';
import styles from './LandingPage.module.scss';

const dueDateCell = (serviceRequestCell) => {
    const { dueDate } = serviceRequestCell;
    if (dueDate === undefined) {
        return '';
    }

    return <FormattedDate value={new Date(dueDate)} format="short" />;
};

const serviceTypeCell = ({ services }) => {
    if (typeof services === 'undefined') {
        return '';
    }
    return services.join(', ');
};

const searchTableFilter = (filterValue) => (row) => {
    const val = filterValue.toLowerCase();
    return (
        row.customerContact.toLowerCase().includes(val)
            || row.claimID.toLowerCase().includes(val)
           // || row.services[0].toLowerCase().includes(val)
           || row.services.find((item) => item.toLowerCase().includes(val))
    );
};

function LandingPage() {
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const [serviceRequestData, setServiceRequestData] = useState([]);
    const [quoteRequestData, setQuoteRequestData] = useState([]);
    const [isLoading, setLoadingState] = useState(false);
    const [sortInfo, setSortInfo] = useState({ headerId: 'nameColumn', isDesc: true });

    const filterServiceItems = useCallback((serviceItems) => {
        // second condition checks if request has a approved quote attached to it
        // if that's the case then move the request to service requests category
        _.each(serviceItems, (value) => {
            if (value.dueDate !== undefined)
            { value.dueDate = new Date(value.dueDate_PAExt.year, value.dueDate_PAExt.month, value.dueDate_PAExt.day); }
        });

        const serviceRequests = _.orderBy(serviceItems.filter((item) => item.requestKind === 'serviceonly'
            || (item.requestKind === 'quoteandservice' && item.nextStepCode === 'FinishWork')), ['createDate'], ['desc']);
        const quoteRequests = _.orderBy(serviceItems.filter((item) => item.requestKind === 'quoteonly'
            || (item.requestKind === 'quoteandservice' && item.nextStepCode !== 'FinishWork')), ['createDate'], ['desc']);
        setQuoteRequestData(quoteRequests);
        setServiceRequestData(serviceRequests);
    }, []);

    const onSort = useCallback((headerId, colValueA, colValueB, isDesc) =>{
        const newSortInfo = {headerId, isDesc};
        setSortInfo((currentInfo) => (_.isEqual(newSortInfo, currentInfo) ? currentInfo : newSortInfo ));
    }, []);


    const headerName = useCallback((id, headerMessages) => {
        const { message, ascending, descending } = headerMessages;
        const { headerId, isDesc } = sortInfo;
        if (headerId === id) {
            return isDesc ? descending : ascending;
        }
        return message;
    }, [sortInfo]);



    useEffect(() => {
        setLoadingState(true);
        ServiceRequestService.getServiceRequestsForVendor(authHeader).then(
            filterServiceItems
        ).finally(() => {
            setLoadingState(false);
        });
        // disable re render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const statesForOperation = useMemo(() => {
        return {
            specialistacceptedwork: translator(messages.serviceRequestProgressAcceptWork),
            specialistdeclined: translator(messages.serviceRequestProgressDeclineWork),
            specialistcompletedwork: translator(messages.serviceRequestProgressCompleteWork),
            specialistcanceled: translator(messages.serviceRequestProgressCancelWork),
            addinvoice: translator(messages.serviceRequestProgressAddInvoice),
            specialistresumedwork: translator(messages.serviceRequestProgressResumeWork),
            addquote: translator(messages.serviceRequestProgressAddQuote)
        };
    }, [translator]);

    const progressCell = useCallback(({ defaultOperation }) => {
        return _.get(statesForOperation, defaultOperation, '');
    }, [statesForOperation]);

    const nameCell = useCallback(({ customerContact, serviceRequestNumber }) => {
        return (
            <Link to={`/service-request-details/${serviceRequestNumber}`}>
                {customerContact}
            </Link>
        );
    }, []);

    const getTableOverrides = useCallback(() => {
        return {
            serviceTypeColumn: { renderCell: serviceTypeCell },
            dueDateColumn: { renderCell: dueDateCell },
            progressColumn: { renderCell: progressCell },
            nameColumn: {
                renderCell: nameCell,
                header: headerName('nameColumn', {
                    message: messages.serviceRequestName,
                    ascending: messages.serviceRequestNameAsc,
                    descending: messages.serviceRequestNameDesc
                }),
                onSort: _.partial(onSort, 'nameColumn')
            },
        };
    }, [nameCell]);

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    const override = {
        '@field': {
            labelPosition: 'left'
        },
        serviceRequestsTable: {
            data: serviceRequestData,
            filter: searchTableFilter
        },
        quoteRequestsTable: {
            data: quoteRequestData,
            filter: searchTableFilter
        },
        ...getTableOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, override, resolvers);
}

export default withRouter(LandingPage);
