import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import messages from './WithdrawInvoicePopup.messages';
import metadata from './WithdrawInvoicePopup.metadata.json5';

function WithdrawInvoicePopup(props) {
    const [withdrawInvoiceVM, setwithdrawInvoiceVM] = useState({});
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { refreshData } = useDataRefresh();
    const { isComponentValid, onValidate } = useValidation('WithdrawInvoicePopup');
    const {
        isOpen,
        onReject,
        onResolve,
        onAfterClose,
        invoiceID
    } = props;

    useEffect(() => {
        setwithdrawInvoiceVM(
            viewModelService.create(
                { invoiceID: invoiceID },
                'cc',
                'edge.capabilities.servicerequest.statement.invoice.dto.InvoiceWithdrawalDTO'
            )
        );
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWithdrawInvoice = useCallback(() => {
        onResolve(withdrawInvoiceVM);
    }, [withdrawInvoiceVM, onResolve]);

    const handleClose = useCallback(() => {
        onReject();
    }, [onReject]);

    const handleInvoiceVMChange = useCallback((newModel) => {
        setwithdrawInvoiceVM(newModel);
        refreshData();
    }, [refreshData]);

    if (_.isEmpty(withdrawInvoiceVM)) {
        return null;
    }

    const overrides = {
        '@field': {
            showOptional: true
        }
    };

    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose} onAfterClose={onAfterClose}>
            <ModalHeader title={translator(messages.withdrawInvoiceTitle)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent.content}
                    model={withdrawInvoiceVM}
                    onValidationChange={onValidate}
                    onModelChange={handleInvoiceVMChange}
                    overrideProps={overrides}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    type="tertiary"
                    onClick={handleClose}
                >
                    {translator(messages.cancel)}
                </Button>
                <Button
                    onClick={handleWithdrawInvoice}
                    disabled={!isComponentValid}
                >
                    {translator(messages.withdrawButton)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

WithdrawInvoicePopup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    onAfterClose: PropTypes.func.isRequired,
    invoiceID: PropTypes.string.isRequired
};

export default WithdrawInvoicePopup;
