import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNextProvider,
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { DocumentComponent } from 'gw-capability-document-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { FileUtil } from 'gw-portals-util-js';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import messages from './AddInvoicePopup.messages';
import metadata from './AddInvoicePopup.metadata.json5';

function AddInvoicePopup(props) {
    const [invoiceVM, setInvoiceVM] = useState({});
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, setLoadingState] = useState(false);
    const { refreshData } = useDataRefresh();
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('AddInvoicePopup');
    const {
        isOpen,
        onReject,
        onResolve,
        onAfterClose,
        serviceRequestID,
        existingDocuments,
        handleNewInvoice,
    } = props;

    useEffect(() => {
        setInvoiceVM(
            viewModelService.create(
                {},
                'cc',
                'edge.capabilities.servicerequest.statement.invoice.dto.ServiceRequestInvoiceDTO'
            )
        );
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateDocuments = useCallback(() => {
        const documentsArray = _.get(invoiceVM, 'documents.value', []);
        return documentsArray.length !== 0;
    }, [invoiceVM]);

    useEffect(() => registerComponentValidation(validateDocuments), [
        registerComponentValidation, validateDocuments
    ]);

    const handleAddInvoice = useCallback(() => {
        onResolve(invoiceVM);
        handleNewInvoice(invoiceVM);
    }, [invoiceVM, onResolve]);

    const handleClose = useCallback(() => {
        setInvoiceVM(
            viewModelService.create(
                {},
                'cc',
                'edge.capabilities.servicerequest.statement.invoice.dto.ServiceRequestInvoiceDTO'
            ));
        onReject();
    }, [onReject]);

    const handleInvoiceVMChange = useCallback((newModel) => {
        setInvoiceVM(newModel);
        refreshData();
    }, [refreshData]);

    const handleUploadDocument = useCallback(async (file) => {
        setLoadingState(true);
        const documentsArray = _.get(invoiceVM, 'documents.value', []);
        const documentMetadataTemplate = {
            serviceRequestId: serviceRequestID,
            releatedTo: 'invoice',
            name: FileUtil.checkFileName(file.name, [...existingDocuments, ...documentsArray]),
            mimeType: file.type,
            sessionID: await ServiceRequestService.generateUploadToken(authHeader)
        };
        const fileExtensions = new Set(['exe', 'jar', 'zip']);
        if (fileExtensions.has(file.name.split('.').pop().toLowerCase())) {
            ModalNextProvider.showAlert({
                title: translator(messages.fileSizeErrorTitle),
                message: translator(messages.fileTypeValidationMessage),
                status: 'warning',
                icon: 'mi-error-outline'
            });
            setLoadingState(false);
            return;
        }
        ServiceRequestService.uploadDocument(file, documentMetadataTemplate, authHeader)
            .then((data) => {
                const existingDocumentsToLink = _.get(invoiceVM, 'existingDocumentsToLink.value', []);
                _.set(invoiceVM, 'documents.value', [...documentsArray, data]);
                _.set(invoiceVM, 'existingDocumentsToLink.value', [...existingDocumentsToLink, data.publicID]);
                setInvoiceVM(invoiceVM);
                refreshData();
            }).catch(() => {
                ModalNextProvider.showAlert({
                    title: messages.error,
                    message: messages.errorMessage,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                setLoadingState(false);
            });
    }, [authHeader, existingDocuments, invoiceVM, refreshData, serviceRequestID]);

    const deleteDocument = useCallback(async (publicID) => {
        setLoadingState(true);
        const documentsArray = _.get(invoiceVM, 'documents.value', []);
        var doc = _.find(documentsArray,{ 'publicID': publicID } );   
        let serviceRequestId =  doc.serviceRequestId   
        ServiceRequestService.removeDocument(serviceRequestId, publicID, authHeader)
            .then((data) => {
                const existingDocumentsToLink = _.get(invoiceVM, 'existingDocumentsToLink.value', []);
                     // const documentSnapshot = _.remove(documentsArray, function(docitem) {
                //     return docitem === publicID;
                //   });
                // const documentlinkSnapshot = _.remove(existingDocumentsToLink, function(docitem) {
                //     return docitem === publicID;
                // });

                const documentlinkSnapshot = _.filter(existingDocumentsToLink, (docitem) => {
                    return docitem.publicID !== publicID;
                });
                _.set(invoiceVM, 'documents.value', [...documentSnapshot]);
                _.set(invoiceVM, 'existingDocumentsToLink.value', [...documentlinkSnapshot]);
                refreshData();
                setInvoiceVM(invoiceVM);
            }).catch(() => {
                ModalNextProvider.showAlert({
                    title: messages.error,
                    message: messages.errorMessage,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                setLoadingState(false);
            });
    }, [authHeader, existingDocuments, invoiceVM, refreshData, serviceRequestID]);

    if (_.isEmpty(invoiceVM)) {
        return null;
    }

    const overrides = {
        '@field': {
            showOptional: true
        },
        fileUpload: {
            uploadDocument: handleUploadDocument,
            downloadDocument: ServiceRequestService.downloadDocumentLink,
            deleteDocument: deleteDocument,
            isLoading: isLoading
        }
    };

    const resolvers = {
        resolveComponentMap: {
            documentcomponent: DocumentComponent,
        }
    };

    return (
        <ModalNext isOpen={isOpen} onRequestClose={handleClose}>
            <ModalHeader title={translator(messages.addInvoiceTitle)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent.content}
                    model={invoiceVM}
                    onValidationChange={onValidate}
                    onModelChange={handleInvoiceVMChange}
                    componentMap={resolvers.resolveComponentMap}
                    overrideProps={overrides}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    type="tertiary"
                    onClick={handleClose}
                >
                    {translator(messages.cancel)}
                </Button>
                <Button
                    onClick={handleAddInvoice}
                    disabled={!isComponentValid}
                >
                    {translator(messages.add)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

AddInvoicePopup.propTypes = {
    isOpen: PropTypes.bool,
    existingDocuments: PropTypes.arrayOf({}),
    serviceRequestID: PropTypes.string,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    onAfterClose: PropTypes.func,
    handleNewInvoice: PropTypes.func
};

AddInvoicePopup.defaultProps = {
    onAfterClose: _.noop,
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    existingDocuments: [],
    serviceRequestID: undefined
};

export default AddInvoicePopup;
