import React, {
    useEffect,
    useContext,
    useCallback,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    InputField,
    DateField,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useDataRefresh } from 'gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { DateUtil } from 'gw-portals-util-js';
import { useValidation } from 'gw-portals-validation-react';
import messages from './DelayWorkActionPopup.messages';

const today = DateUtil.currentDateAtMidnight();

function DelayWorkActionPopup(props) {
    const translator = useContext(TranslatorContext);
    const {
        serviceRequest,
        viewModelService,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [actionVM, updateActionVM] = useState();
    const [isVMInitialized, setVMInitialized] = useState(false);
    const { isComponentValid, registerComponentValidation } = useValidation('DelayWorkActionPopup');
    const { refreshData } = useDataRefresh();

    const writeValue = useCallback(
        (value, path) => {
            _.set(actionVM, `${path}.value`, value);
            updateActionVM(actionVM);
            refreshData();
        },
        [actionVM, refreshData]
    );

    const handleDefaultAction = useCallback(() => {
        if (isComponentValid) {
            onResolve(actionVM.value);
        }
    }, [actionVM, isComponentValid, onResolve]);

    const validateForm = useCallback(() => {
        if (isVMInitialized) {
            return actionVM.aspects.valid && actionVM.aspects.subtreeValid;
        }
        return false;
    }, [actionVM, isVMInitialized]);

    useEffect(() => {
        if (isVMInitialized) {
            registerComponentValidation(validateForm);
        }
    }, [actionVM, isVMInitialized, registerComponentValidation, validateForm]);

    useEffect(() => {
        updateActionVM(
            viewModelService.create({
                serviceRequestNumber: serviceRequest.serviceRequestNumber,
                newCompletionDate: new Date()
            }, 'cc', 'edge.capabilities.servicerequest.dto.ServiceRequestReportDelayDTO')
        );
        setVMInitialized(true);
        // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (_.isEmpty(actionVM)) return null;

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={translator(messages.actionTitleDelayWork)} />
            <ModalBody
                id="modalDelayWork"
                contentLayout={{
                    component: 'grid'
                }}
            >
                <InputField
                    id="reason"
                    path="reason"
                    label={translator(messages.actionReason)}
                    value={_.get(actionVM, 'reason.value')}
                    onValueChange={writeValue}
                />
                <DateField
                    id="newCompletionDate"
                    path="newCompletionDate"
                    label={translator(messages.actionCompletionDate)}
                    showTime={false}
                    format="short"
                    dataType="string"
                    minDate={today}
                    value={_.get(actionVM, 'newCompletionDate.value')}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="secondary">
                    {translator(messages.actionButtonCancel)}
                </Button>
                <Button
                    onClick={handleDefaultAction}
                    disabled={!isComponentValid}
                >
                    {translator(messages.actionButtonDelayWork)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

DelayWorkActionPopup.propTypes = {
    serviceRequest: PropTypes.shape([]).isRequired,
    viewModelService: PropTypes.shape([]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default DelayWorkActionPopup;
