import React, {
    useEffect,
    useCallback,
    useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    InputField,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useDataRefresh } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';

function CommonWorkActionPopup(props) {
    const {
        serviceRequest,
        modalTitle,
        modalMessage,
        defaultButtonLabel,
        cancelButtonLabel,
        viewModelService,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [actionVM, updateActionVM] = useState();
    const [isVMInitialized, setVMInitialized] = useState(false);
    const { isComponentValid, registerComponentValidation } = useValidation('CommonWorkActionPopup');
    const { refreshData } = useDataRefresh();

    const writeValue = useCallback(
        (value, path) => {
            _.set(actionVM, `${path}.value`, value);
            updateActionVM(actionVM);
            refreshData();
        },
        [actionVM, refreshData]
    );

    const handleDefaultAction = useCallback(() => {
        if (isComponentValid) {
            onResolve(actionVM.value);
        }
    }, [actionVM, isComponentValid, onResolve]);

    const validateForm = useCallback(() => {
        if (isVMInitialized) {
            return actionVM.aspects.valid && actionVM.aspects.subtreeValid;
        }
        return false;
    }, [actionVM, isVMInitialized]);

    useEffect(() => {
        if (isVMInitialized) {
            registerComponentValidation(validateForm);
        }
    }, [actionVM, registerComponentValidation, validateForm, isVMInitialized]);

    useEffect(() => {
        updateActionVM(
            viewModelService.create({
                serviceRequestNumber: serviceRequest.serviceRequestNumber
            }, 'cc', 'edge.capabilities.servicerequest.dto.ServiceRequestActionDTO')
        );
        setVMInitialized(true);
    // only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (_.isEmpty(actionVM)) return null;

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={modalTitle} />
            <ModalBody>
                <InputField
                    id="actionReason"
                    path="description"
                    label={modalMessage}
                    value={_.get(actionVM, 'description.value')}
                    onValueChange={writeValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="secondary">
                    {cancelButtonLabel}
                </Button>
                <Button
                    onClick={handleDefaultAction}
                    disabled={!isComponentValid}
                >
                    {defaultButtonLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

CommonWorkActionPopup.propTypes = {
    serviceRequest: PropTypes.shape([]).isRequired,
    modalTitle: PropTypes.string.isRequired,
    modalMessage: PropTypes.string.isRequired,
    defaultButtonLabel: PropTypes.string.isRequired,
    cancelButtonLabel: PropTypes.string.isRequired,
    viewModelService: PropTypes.shape([]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default CommonWorkActionPopup;
