import { defineMessages } from 'react-intl';

export default defineMessages({
    backToSignIn: {
        id: 'auth.components.common.UserRegisterMessageComponent.Back to Sign In',
        defaultMessage: 'Back to Sign In'
    },
    backToRegistration: {
        id: 'auth.components.common.UserRegisterMessageComponent.Back to Registration',
        defaultMessage: 'Back to vendor registration'
    },
});
