import React, { useState, useEffect } from "react";
import { Document, Page , pdfjs} from "react-pdf";



/*
* Custom component to display a PDF
*/
export default function PDFViewer(props) {

    const [numPages, setNumPages] = useState(null);

    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
         pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
       })

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
      }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    return (
        <>
        <Document 
            file="/Terms2.pdf"
            onLoadSuccess = {onDocumentLoadSuccess}
        >
        {Array.apply(null, Array(numPages))
            .map((x, i)=>i+1)
            .map(page => <Page pageNumber={page}/>)}

        </Document>
        </>
    );
}
