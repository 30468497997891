import React from 'react';
import {
    Button,
    ModalNext,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalNextProvider
} from '@jutro/components';
import styles from './PdfCustomModal.scss'


function PdfCustomModal({
    isOpen,
    onResolve,
    // onReject, // use sparingly
    confirmButtonText,
    cancelButtonText,
    message,
    children,
}) {
    function handleOK() {
        return onResolve(confirmButtonText);
    }

    function handleCancel() {
        return   onResolve(cancelButtonText);
    }

    function handleClose() {
        onResolve(cancelButtonText);
    }

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={handleClose}
        >
            <ModalHeader
                title=" "
                status="info"
                onClose={handleClose}
            />
            <ModalBody>
                <div>{message}</div>
                <div>{children}</div>
            </ModalBody>
            <ModalFooter contentLayout={{
                        component: 'flex',
                        componentProps: {
                            justifyContent: 'center'
                        }
                    }}
>
                <Button
                    type="primary"
                    onClick={handleOK}
                >
                    {confirmButtonText}
                </Button> 
              
            </ModalFooter>
        </ModalNext>
    );
}

export default PdfCustomModal;
