import {
    useState, useEffect, useCallback, useContext, useMemo
} from 'react';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {InlineLoader} from '@jutro/components'
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata, validateContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication, AUTH_ERRORS } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import AuthMessageComponent from '../AuthMessageComponent/AuthMessageComponent';
import { checkEmailValidity , isEmpty} from '../LoginUtil';
import UserRegisterMessageComponent from '../UserRegisterMessageComponent/UserRegisterMessageComponent';
import metadata from './UserSignUpComponent.metadata.json5';
import messages from './UserSignUpComponent.messages';
import styles from './UserSignUpComponent.module.scss';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import { ModalNextProvider , CheckboxField } from '@jutro/components'
import {
    PdfCustomModal
} from 'gw-capability-servicerequest-react';
import { PDFViewer } from 'gw-document-pdfdisplay-react';
import appConfig from 'app-config';

const {siteKeyMap, useRecaptcha, useCaptcha } = appConfig;
const PAGEVIEW = {
    registerFormView: 'REGISTER_FORM_VIEW',
    successView: 'REGISTER_SUCCESS',
    failFeinView: 'REGISTER_FEIN_FAIL',
    failUserIdView: 'REGISTER_UserID_FAIL'
};

const ERRORVIEW = {
    feinnotExist: 'REGISTER_FORM_VIEW',
    successView: 'REGISTER_SUCCESS',
    failFeinView: 'REGISTER_FEIN_FAIL',
    failUserIdView: 'REGISTER_UserID_FAIL'
};

function UserSignUpComponent(props) {
    const translator = useContext(TranslatorContext);
    const [pageError, updatePageError] = useState();
    const [formData, updateFormData] = useState({});
    const { isComponentValid, registerComponentValidation } = useValidation('UserSignUpComponent');
    const { history, successPath, onBackToSignIn } = props;
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [captchaChecked, updateCaptchaChecked] = useState(false);
    const [pageView, updatePageView] = useState(PAGEVIEW.registerFormView);
    const [descriptionMessage, updateDescriptionMessage] = useState("");
    const [isLoading, setLoadingState] = useState(false);
    const [result, updateResult] = useState(null);
    const [agreeTerm, updateAgreeTerm] = useState(false);

    const handleSuccessClick = useCallback((data) => {
        if (data.status === 'Success') updatePageView(PAGEVIEW.successView);
        else {
            const description = data.description
            updateDescriptionMessage(description);

            updatePageView(PAGEVIEW.failFeinView);
        }
    }, [pageView, descriptionMessage]);

    const doSubmit = () => {
        const user_captcha = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha)==true) {
            alert('Captcha Matched');
            loadCaptchaEnginge(6); 
            document.getElementById('user_captcha_input').value = '';
        } else {
            alert('Captcha Does Not Match');
            document.getElementById('user_captcha_input').value = '';
        }
    };

    const handleRegisterClick = useCallback(() => {
        updatePageView(PAGEVIEW.registerFormView);
    }, [pageView]);

    const handleAcceptWork = useCallback(
        (actionData) => {
            updateResult(actionData);
        }, []
    );

    const triggerModal = ()=> {
         ModalNextProvider.showModal(
                   <PdfCustomModal message="" confirmButtonText="Close" cancelButtonText="Disagree">
                     <PDFViewer></PDFViewer>
                   </PdfCustomModal>
               ).then(
                   handleAcceptWork)
                   .catch(_.noop);
    };

    const onValueChangeFuc = (value) => {
            updateAgreeTerm(value)
        };  
 

    const handleUserReg = useCallback(
        (evt) => {
            if (evt) {
                evt.preventDefault();
            }

            const email = _.get(formData, 'emailAddress');
            const firstname = _.get(formData, 'firstName');
            const lastname = _.get(formData, 'lastName');
            const fein = _.get(formData, 'FEIN');
            const userType = _.get(formData, 'userType');
            const telephone = _.get(formData, 'telephone');
            const userid = _.get(formData, 'userNum') ;
            const reg = /^[0-9]{9}/;    
            
            if (!checkEmailValidity(email)) {
                updatePageError("userInvalidEmail");
                setLoadingState(false);
                return Promise.resolve();
            }
            if (isEmpty(firstname)) {
                updatePageError('userFirstNameIsEmpty');
                setLoadingState(false);
                return Promise.resolve();
            }
            if (isEmpty(lastname)) {
                updatePageError('userLastNameIsEmpty');
                setLoadingState(false);
                return Promise.resolve();
            }
            if (isEmpty(fein) ) {
                updatePageError('feinIsEmptyError');
                setLoadingState(false);
                return Promise.resolve();
            }

            if( !reg.test(fein)){
                updatePageError('feinFormatError');
                setLoadingState(false);
                return Promise.resolve();

            }

            if (isEmpty(userType)) {
                updatePageError('userTypeError');
                setLoadingState(false);
                return Promise.resolve();
            }
            if (isEmpty(telephone)) {
                updatePageError('telephoneError');
                setLoadingState(false);
                return Promise.resolve();
            }
            if (!isEmpty(userid)) {
                if (!_.startsWith(userid, 'b-')) {
                    updatePageError('userIdError');
                    setLoadingState(false);
                    return Promise.resolve();
                }
            }

               if (useRecaptcha && isEmpty(captchaChecked) && captchaChecked===false ) {
                   updatePageError('captchaCheckedError');
                   return Promise.resolve();
               }         
            
             setLoadingState(true);
             ServiceRequestService.createNewUser(userid ? userid: '', firstname,lastname, email, fein, telephone, userType, {})
             .then((data)=>{
                 updateCaptchaChecked(false)
                 handleSuccessClick(data);
                 updateFormData({});
                 updateAgreeTerm(false)
                 Promise.resolve();
                }
                ).catch(() => {
                    setLoadingState(false);
                }).finally(() => {
                    setLoadingState(false);
                });
        },
        [formData, isComponentValid,pageView, result, agreeTerm, captchaChecked]
    );

    const captchaCallbackfunction = useCallback(
        (response) => {
          }
    );

    const verifyCaptchaCallbackfunction = useCallback(
        (response) => {
            if(response)
             updateCaptchaChecked(true)
          }
    );

    const captchaExpiredCallbackfunction = useCallback(
        (response) => {
            if(!response)
            updateCaptchaChecked(false)
          }
    );
    const readValue = useCallback((id, path) => _.get(formData, path), [formData]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const overrideProps = useMemo(
        () => ({
            '@field': {
                onValueChange: writeValue
            },
            errorMsg: {
                visible: !_.isUndefined(pageError),
                message: translator(messages[pageError])
            },
            signUpButton: {
                trigger: handleUserReg,
                visible: !isLoading,
                disabled: !agreeTerm
            },
            successfulContainer: {
                visible: pageView === PAGEVIEW.successView
            },
            successfulContainerReview: {
                visible: pageView === PAGEVIEW.successView
            },
            successfulContainerQuestion: {
                visible: pageView === PAGEVIEW.successView
            },
            userSignUpContainer: {
                visible: pageView === PAGEVIEW.registerFormView
            },
            failedContainer: {
                description: descriptionMessage,
                visible: pageView === PAGEVIEW.failFeinView
            },
            failedUserIdContainer: {
                visible: pageView === PAGEVIEW.failUserIdView
            },
            // captchaContainer: {
            //      visible : useRecaptcha
            //  },

            inlineLoaderCom: {
                visible: isLoading,
                loading: true
            },
            CheckboxField: {
                value: agreeTerm,

            },
            termCheck: {
                value: agreeTerm,

            },
            captchaele: {
                sitekey: (_.find(siteKeyMap, function (o) {
                    const found = _.startsWith(window.location, o.key);
                    if(found){
                        return o;
                    }
                }).value
                ),
                onloadCallback: captchaCallbackfunction,
                verifyCallback: verifyCaptchaCallbackfunction,
                expiredCallback: captchaExpiredCallbackfunction
            }

        }),
        [handleUserReg, pageError, pageView, translator, writeValue, isLoading,agreeTerm]
    );

    const resolvers = useMemo(
        () => ({
            resolveValue: readValue,
            resolveCallbackMap: {
                //handleBackToSignIn: handleRegisterClick,
                onCallback: handleRegisterClick,
                openTermPopup: triggerModal,
                onValueChangeFuc: onValueChangeFuc,
                onTriggerFuc: triggerModal,
                handleCaptchaFun: captchaCallbackfunction
            },
            resolveComponentMap: {
                userRegisterMessageComponent: UserRegisterMessageComponent//,
            // CaptchaComponent: CaptchaComponent
            },
            resolveClassNameMap: styles
        }),
        [handleRegisterClick, readValue]
    );

    
    const validateForm = useCallback(() => {
        return validateContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
    }, [overrideProps, resolvers]);

    useEffect(() => {
        registerComponentValidation(validateForm);
    }, [registerComponentValidation, validateForm]);

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

UserSignUpComponent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    successPath: PropTypes.string.isRequired,
    onBackToSignIn: PropTypes.func,
    onBackToLanding: PropTypes.func,
    captchaCallbackfunction: PropTypes.func
};

UserSignUpComponent.defaultProps = {
    onBackToSignIn: undefined,
    onBackToLanding: undefined,
    captchaCallbackfunction: undefined
};

export default withRouter(UserSignUpComponent);
