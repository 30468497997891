import { Switch, Route, Redirect } from 'react-router-dom';
import React, {
    useMemo,
    useState,
    useContext,
    useCallback,
    useEffect
} from 'react';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import {
    ModalNextProvider,
} from '@jutro/components';
import { DropdownMenuLink } from '@jutro/router';
import { AppFloorPlan } from '@jutro/floorplan';
import { TranslatorContext } from '@jutro/locale';
import { Preferences } from 'gw-pages-platform-react';
import { DynamicRoute, useAuthentication, withAuthContextProvider } from 'gw-digital-auth-react';
import { LoginCapabilityComponentMap, LoginCapabilityComponents } from 'gw-portals-login-react';
import vmTranslator from 'gw-platform-translations';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { filterCapabilityRoutes } from 'gw-portals-config-js';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';


import FaqPage from '../pages/SWIFFAQPage/Faq/FaqPage';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import EntryPage from '../pages/EntryPage/EntryPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import ServiceRequestDetailsPage from '../pages/ServiceRequestDetailsPage/ServiceRequestDetailsPage';
import messages from './App.messages';
import appRoutes from './App.routes.metadata.json5';
import Recaptcha from 'react-recaptcha';
import {PDFViewer} from 'gw-document-pdfdisplay-react';
import {PdfCustomModal} from 'gw-capability-servicerequest-react'  
import UserRegisterEntryPage from '../pages/UserRegisterEntryPage/UserRegisterEntryPage';
import UserApprovePage from '../pages/UserApprovePage/UserApprovePage';

const { capabilitiesConfig, env } = appConfig;

const componentMap = {
    landingpage: LandingPage,
    faqpage: FaqPage,
    preferences: Preferences,
    servicerequestdetailspage: ServiceRequestDetailsPage,
    userApprovePage: UserApprovePage
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...LoginCapabilityComponentMap,
        // replace the native IMG component with a proxied version
        img: { component: 'img' },
        Recaptcha: { component: 'Recaptcha' },
        PDFViewer: { component: 'PDFViewer' },
        PdfCustomModal: { component: 'PdfCustomModal' }
    },
    {
        ...platformComponents,
        ...LoginCapabilityComponents,
        img: ImageComponent,
        Recaptcha: Recaptcha,
        PDFViewer: PDFViewer,
        PdfCustomModal: PdfCustomModal
    }
);

function App() {
    const auth = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [viewModelService, updateViewModelService] = useState(undefined);

    const getAvatarLinks = useCallback(() => {
        return [
            <DropdownMenuLink to="/faq">{translator(messages.faq)}</DropdownMenuLink>,
            <DropdownMenuLink to="/preferences">{translator(messages.preferences)}</DropdownMenuLink>,
            <DropdownMenuLink to="/userApprove">{translator(messages.userApprove)}</DropdownMenuLink>,
            <DropdownMenuLink onClick={auth.logout}>{translator(messages.logout)}</DropdownMenuLink>
        ];
    }, [translator, auth]);

    const getLogoSrc = useCallback(() => {
        const prefix = _.get(env, 'DEPLOYMENT_PATH', '').replace(/\/$/, '');
        //  return `${prefix}/branding/logo.svg`;
        return `${prefix}/branding/L_I_left_cmyk.jpg`;
    }, []);

    // (Router) Title is required and used for (Browser) title
    // in a format of "${title} - ${appName}". In order to show
    // the application name only, it needs to be an empty value.
    const routesWithoutTitle = useMemo(() => {
        return filterCapabilityRoutes(capabilitiesConfig, appRoutes.routes)
            .map((route) => {
                return {
                    ...route,
                    title: ''
                };
            });
    }, []);

    const floorPlansConfig = useMemo(() => {
        return {
            scrollContent: false,
            routes: routesWithoutTitle,
            useAuthInfo: false,
            header: {
                avatarChildren: getAvatarLinks(),
                avatarProps: {
                    username: auth.user_name,
                    title: auth.name,
                    subtitle: auth.user_name
                },
                className: 'appheader',
                logoSrc: getLogoSrc(),
                showAppSwitcher: false,
                showNotifications: false,
                useAuthInfo: false
            }
        };
    }, [auth.name, auth.user_name, getAvatarLinks, getLogoSrc, routesWithoutTitle]);

    useEffect(
        () => {
            const translatorFn = vmTranslator(translator);
            import(
                /* webpackChunkName: "product-metadata" */
                // eslint-disable-next-line import/no-unresolved
                'product-metadata'
            ).then((productMetadata) => {
                const { default: result } = productMetadata;
                updateViewModelService(
                    ViewModelServiceFactory.getViewModelService(result, translatorFn)
                );
            });
        },
        [translator]
    );

    const handleError = useCallback((error) => {
        ModalNextProvider.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title: 'An Error',
            message: error.message,
        }).then((result) => {
            if (result === 'confirm') {
                // TODO handle click on confirm
            }
        }).catch(_.noop);
    }, []);

    return (
        <AccurateBreakpointPropagation>
            <ErrorBoundary onError={handleError}>
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <ModalNextProvider />
                    <Switch>
                        <Route exact path="/login-page" component={EntryPage} />
                        <Route exact path="/auth/register" component={UserRegisterEntryPage} />
                        <Route exact path="/auth/resetpassword" component={ResetPasswordPage} />
                        <DynamicRoute path="/">
                            <AppFloorPlan
                                componentMap={componentMap}
                                {...floorPlansConfig}
                            />
                        </DynamicRoute>
                    </Switch>
                </ViewModelServiceContext.Provider>
            </ErrorBoundary>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthContextProvider()(App);
