import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { consumerThemeConfig } from '@jutro/theme-styles';
import { LocaleService } from 'gw-portals-i18n-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import App from './app/App';

const { trackingConfig = {}, authentication = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

let cloudAuthConfig = {};
if (authentication.authServer === 'okta') {
    cloudAuthConfig = authentication.servers.okta;
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLocaleChange: true,
    config: [cloudAuthConfig],
    routerBasename: '/vendor-engage',
    onInit: () => {
        LocaleService.register();
    },
    appName: {
        id: 'digital.appName',
        defaultMessage: 'VendorEngage'
    },
    themeConfig: consumerThemeConfig,
    appDescription: 'VendorEngage'
});
