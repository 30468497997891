import { defineMessages } from 'react-intl';

export default defineMessages({
    logoAlt: {
        id: 'jutro-router.ApplicationHeader.logo alt',
        defaultMessage: 'Go back to the Homepage',
    },
    appName: {
        id: 'portal.name',
        defaultMessage: 'VendorEngage'
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ'
    },
    logout: {
        id: 'header.links.Logout',
        defaultMessage: 'Logout'
    },
    preferences: {
        id: 'header.links.preferences',
        defaultMessage: 'Preferences'
    },
    userApprove: {
        id: 'portal.links.Userapprove',
        defaultMessage: 'User Administration'
    }
});
