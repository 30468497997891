import { useState, useCallback } from 'react';

// Used to force re-render a page after setting an data from the viewModelForm
export default function useDataRefresh() {
    const [toggle, flip] = useState(false);

    const refreshData = useCallback(() => {
        flip(!toggle);
    }, [toggle]);

    return { refreshData };
}
