import { defineMessages } from 'react-intl';

export default defineMessages({
    actionTitleAcceptWork: {
        id: 'servicerequest.views.accept-work.Accept Work',
        defaultMessage: 'Accept Work'
    },
    actionErrorCompletionDate: {
        id: 'servicerequest.views.accept-work.DateInFuture',
        defaultMessage: 'The expected completion date must be a date in the future.'
    },
    actionCompletionDate: {
        id: 'servicerequest.views.accept-work.Expected Quote Completion Date',
        defaultMessage: 'Expected Quote Completion Date'
    },
    actionServiceCompletionDate: {
        id: 'servicerequest.views.accept-work.Expected Service Completion Date',
        defaultMessage: 'Expected Service Completion Date'
    },
    actionButtonAccept: {
        id: 'servicerequest.views.accept-work.button.Accept',
        defaultMessage: 'Accept'
    },
    actionButtonCancel: {
        id: 'servicerequest.views.accept-work.button.Cancel',
        defaultMessage: 'Cancel'
    }
});
