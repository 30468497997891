import React, {
    useState,
    useEffect,
    useMemo,
    useContext,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalNextProvider,
} from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { DocumentComponent } from 'gw-capability-document-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { FileUtil } from 'gw-portals-util-js';
import { ServiceRequestService } from 'gw-capability-servicerequest';
import messages from './AddQuoteModal.messages';
import metadata from './AddQuoteModal.metadata.json5';

function AddQuoteModal(props) {
    const [quoteVM, setQuoteVM] = useState({});
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, setLoadingState] = useState(false);
    const { refreshData } = useDataRefresh();
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('AddQuoteModal');
    const {
        isOpen,
        onReject,
        onResolve,
        quoteValue,
        onAfterClose,
        serviceRequestID,
        existingDocuments,
        handleNewQuote
    } = props;

    const quoteAlreadyExists = useMemo(() => !_.isEmpty(quoteValue), [quoteValue]);

    useEffect(() => {
        if (quoteAlreadyExists) {
            quoteValue.existingDocumentsToLink = quoteValue.documents.map(
                (document) => document.publicID
            );
        }
        setQuoteVM(
            viewModelService.create(
                quoteValue,
                'cc',
                'edge.capabilities.servicerequest.statement.quote.dto.ServiceRequestQuoteDTO'
            )
        );
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateDocuments = useCallback(() => {
        const documentsArray = _.get(quoteVM, 'documents.value', []);
        return documentsArray.length !== 0;
    }, [quoteVM]);

    useEffect(() => registerComponentValidation(validateDocuments), [
        registerComponentValidation, validateDocuments
    ]);

    const handleAddQuote = useCallback(() => {
        handleNewQuote(quoteVM);
        onResolve();
        //onResolve(quoteVM);
    }, [quoteVM, onResolve]);

    const handleClose = useCallback(() => {
        onReject();
    }, [onReject]);

    const handleQuoteVMChange = useCallback((newModel) => {
        setQuoteVM(newModel);
        refreshData();
    }, [refreshData]);

    const handleUploadDocument = useCallback(async (file) => {
        setLoadingState(true);
        const fileExtensions = new Set(['exe', 'jar', 'zip']);
        if (fileExtensions.has(file.name.split('.').pop().toLowerCase())) {
            ModalNextProvider.showAlert({
                title: translator(messages.fileSizeErrorTitle),
                message: translator(messages.fileTypeValidationMessage),
                status: 'warning',
                icon: 'mi-error-outline'
            });
            setLoadingState(false);
            return;
        }
        const documentsArray = _.get(quoteVM, 'documents.value', []);
        const documentMetadataTemplate = {
            serviceRequestId: serviceRequestID,
            releatedTo: 'quote',
            name: FileUtil.checkFileName(file.name, [...existingDocuments, ...documentsArray]),
            mimeType: file.type,
            sessionID: await ServiceRequestService.generateUploadToken(authHeader)
        };
        ServiceRequestService.uploadDocument(file, documentMetadataTemplate, authHeader)
            .then((data) => {
                const existingDocumentsToLink = _.get(quoteVM, 'existingDocumentsToLink.value', []);
                _.set(quoteVM, 'documents.value', [...documentsArray, data]);
                _.set(quoteVM, 'existingDocumentsToLink.value', [...existingDocumentsToLink, data.publicID]);
                setQuoteVM(quoteVM);
                refreshData();
            }).catch(() => {
                ModalNextProvider.showAlert({
                    title: messages.error,
                    message: messages.errorMessage,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                setLoadingState(false);
            });
    }, [authHeader, existingDocuments, quoteVM, refreshData, serviceRequestID]);

    const deleteDocument = useCallback(async (publicID) => {
        setLoadingState(true);
        const documentsArray = _.get(quoteVM, 'documents.value', []);
        var doc = _.find(documentsArray,{ 'publicID': publicID } );
        let serviceRequestId =  doc.serviceRequestId   
        ServiceRequestService.removeDocument(serviceRequestId, publicID, authHeader)
            .then((data) => {
                const existingDocumentsToLink = _.get(quoteVM, 'existingDocumentsToLink.value', []);
                const documentSnapshot = _.filter(documentsArray, function(docitem) {
                    return docitem.publicID !== publicID;
                  });
                const documentlinkSnapshot = _.filter(existingDocumentsToLink, function(docitem) {
                    return docitem.publicID !== publicID;
                });
                _.set(quoteVM, 'documents.value', [...documentSnapshot]);
                _.set(quoteVM, 'existingDocumentsToLink.value', [...documentlinkSnapshot]);
                refreshData();
                setQuoteVM(quoteVM);
            }).catch(() => {
                ModalNextProvider.showAlert({
                    title: messages.error,
                    message: messages.errorMessage,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            }).finally(() => {
                setLoadingState(false);
            });
    }, [authHeader, existingDocuments, quoteVM, refreshData, serviceRequestID]);

    if (_.isEmpty(quoteVM)) {
        return null;
    }

    const overrides = {
        '@field': {
            showOptional: true
        },
        fileUpload: {
            uploadDocument: handleUploadDocument,
            downloadDocument: ServiceRequestService.downloadDocumentLink,
            deleteDocument: deleteDocument,
            isLoading: isLoading
        }
    };

    const resolvers = {
        resolveComponentMap: {
            documentcomponent: DocumentComponent,
        }
    };

    return (
        isOpen && (
            <ModalNext isOpen={isOpen} onRequestClose={handleClose}>
                <ModalHeader title={quoteAlreadyExists
                    ? translator(messages.reviseQuote) : translator(messages.addQuote)}
                />
                <ModalBody>
                    <ViewModelForm
                        uiProps={metadata.componentContent.content}
                        model={quoteVM}
                        onValidationChange={onValidate}
                        onModelChange={handleQuoteVMChange}
                        componentMap={resolvers.resolveComponentMap}
                        overrideProps={overrides}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="tertiary"
                        onClick={handleClose}
                    >
                        {translator(messages.cancel)}
                    </Button>
                    <Button
                        onClick={handleAddQuote}
                        disabled={!isComponentValid}
                    >
                        {translator(messages.save)}
                    </Button>
                </ModalFooter>
            </ModalNext>
        )
    );
}

AddQuoteModal.propTypes = {
    quoteValue: PropTypes.shape({
        existingDocumentsToLink: PropTypes.arrayOf({}),
        documents: PropTypes.arrayOf({}),
    }),
    existingDocuments: PropTypes.arrayOf({}),
    serviceRequestID: PropTypes.string,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    onAfterClose: PropTypes.func,
};

AddQuoteModal.defaultProps = {
    onAfterClose: _.noop,
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    quoteValue: {},
    existingDocuments: [],
    serviceRequestID: undefined
};

export default AddQuoteModal;
