import { defineMessages } from 'react-intl';

export default defineMessages({
    noPolicyChangeDocuments: {
        id: 'gateway.directives.gateway-documents.There are no documents associated with this policy change',
        defaultMessage: 'There are no documents associated with this policy change',
    },
    modalError: {
        id: 'document.pages.document-page.modal.Error',
        defaultMessage: 'Error'
    },
    errorLoadingDocument: {
        id: 'document.pages.document-page.modal.Error loading documents',
        defaultMessage: 'Error loading documents'
    },
});
