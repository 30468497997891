import LoginComponent from './LoginComponent/LoginComponent';
import SignUpComponent from './SignUpComponent/SignUpComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent/ForgotPasswordComponent';
import ResetPasswordComponent from './ResetPasswordComponent/ResetPasswordComponent';
import UserSignUpComponent from './UserSignUpComponent/UserSignUpComponent';
import UserRegisterMessageComponent from './UserRegisterMessageComponent/UserRegisterMessageComponent';

const LoginCapabilityComponentMap = {
    LoginComponent: { component: 'LoginComponent' },
    SignUpComponent: { component: 'SignUpComponent' },
    ForgotPasswordComponent: { component: 'ForgotPasswordComponent' },
    ResetPasswordComponent: { component: 'ResetPasswordComponent' },
    UserSignUpComponent: { component: 'UserSignUpComponent'},
    UserRegisterMessageComponent: { component: 'UserRegisterMessageComponent'}
};

const LoginCapabilityComponents = {
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserSignUpComponent,
    UserRegisterMessageComponent
};

export {
    LoginCapabilityComponentMap,
    LoginCapabilityComponents,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserSignUpComponent,
    UserRegisterMessageComponent
};
