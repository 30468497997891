import { defineMessages } from 'react-intl';

export default defineMessages({
    actionTitleDelayWork: {
        id: 'servicerequest.views.delay-work.Report a Delay',
        defaultMessage: 'Report a Delay'
    },
    actionReason: {
        id: 'servicerequest.views.delay-work.Reason',
        defaultMessage: 'Reason'
    },
    actionCompletionDate: {
        id: 'servicerequest.views.delay-work.Expected Completion Date',
        defaultMessage: 'Expected Completion Date'
    },
    actionButtonDelayWork: {
        id: 'servicerequest.views.delay-work.button.Delay Work',
        defaultMessage: 'Delay Work'
    },
    actionButtonCancel: {
        id: 'servicerequest.views.delay-work.button.Cancel',
        defaultMessage: 'Cancel'
    }
});
