import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { DocumentUploadService, DocumentDownloadService } from 'gw-portals-document-js';

export default class ServiceRequestService {
    static getServiceRequestsForVendor(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'getServiceRequestsForVendor', [], additionalHeaders);
    }

    static getServiceRequestDetails(serviceRequestNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'getServiceRequestDetails', [serviceRequestNumber], additionalHeaders);
    }

    static getMessagesForSpecialist(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'getMessagesForSpecialist', [], additionalHeaders);
    }

    static acceptWork(acceptWorkDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'acceptWork', [acceptWorkDTO], additionalHeaders);
    }

    static declineWork(serviceRequestAction, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'declineWork', [serviceRequestAction], additionalHeaders);
    }

    static cancelServiceRequest(serviceRequestAction, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'cancelServiceRequest', [serviceRequestAction], additionalHeaders);
    }

    static completeWork(serviceRequestNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'completeWork', [serviceRequestNumber], additionalHeaders);
    }

    static reportBlocked(serviceRequestAction, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'reportBlocked', [serviceRequestAction], additionalHeaders);
    }

    static uploadDocument(file, documentMetadata, additionalHeaders = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('serviceRequestDocUpload'), file, documentMetadata, additionalHeaders);
    }

    static generateUploadToken(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequestDocs'), 'generateUploadToken', [], additionalHeaders);
    }

    static addOrReplaceQuote(publicID, quoteDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'addOrReplaceQuote', [publicID, quoteDTO], additionalHeaders);
    }

    static reportDelay(serviceRequestReportDelay, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'reportDelay', [serviceRequestReportDelay], additionalHeaders);
    }

    static addInvoice(serviceRequestNumber, invoice, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'addInvoice', [serviceRequestNumber, invoice], additionalHeaders);
    }

    static withdrawInvoice(withdrawInvoice, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'withdrawInvoice', [withdrawInvoice], additionalHeaders);
    }

    static resumeWork(serviceRequestNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'resumeWork', [serviceRequestNumber], additionalHeaders);
    }

    static createMessage(serviceRequestNumber, message, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequest'), 'createMessage', [serviceRequestNumber, message], additionalHeaders);
    }

    static createNewUser(userid, firstname, lastname, email, fein, telephone, userType, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequestUser'), 'createNewUser', [userid, firstname, lastname, email, fein, telephone, userType], additionalHeaders);
    }

    static downloadDocumentLink(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('serviceRequestDownloadDocument'), documentID, token);
    }

    static getRegisterUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRegisterUser'), 'getRegisterUser', [], additionalHeaders);
    }
    
    static approveRegisterUser(userDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRegisterUser'), 'approveRegisterUser', [userDTO], additionalHeaders);
    }

    static rejectRegisterUser(userDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRegisterUser'), 'rejectRegisterUser', [userDTO], additionalHeaders);
    }

    static downloadDocumentLink(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('serviceRequestDownloadDocument'), documentID, token);
    }

    static removeDocument(serviceRequestId, publicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('serviceRequestDocs'), 'removeDocument', [serviceRequestId, publicID], additionalHeaders);
    }
}
