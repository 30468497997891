import { defineMessages } from 'react-intl';

export default defineMessages({
    reviseQuote: {
        id: 'servicerequest.views.add-revise-quote.Revise Quote',
        defaultMessage: 'Revise Quote'
    },
    addQuote: {
        id: 'servicerequest.views.add-revise-quote.Add Quote',
        defaultMessage: 'Add Quote'
    },
    totalAmount: {
        id: 'servicerequest.views.add-revise-quote.Total Amount',
        defaultMessage: 'Total amount',
    },
    totalWorkDaysNeeded: {
        id: 'servicerequest.views.add-revise-quote.Total Work Days Needed',
        defaultMessage: 'Total work days needed',
    },
    referenceNumber: {
        id: 'servicerequest.views.add-revise-quote.Reference number',
        defaultMessage: 'Reference number',
    },
    description: {
        id: 'servicerequest.views.add-revise-quote.Description',
        defaultMessage: 'Description',
    },
    error: {
        id: 'servicerequest.views.add-revise-quote.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'servicerequest.views.add-revise-quote.Something went wrong during this process.',
        defaultMessage: 'Something went wrong during this process.'
    },
    cancel: {
        id: 'servicerequest.add-revise-quote.buttons.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'servicerequest.views.add-revise-quote.button.Save',
        defaultMessage: 'Save'
    },
    fileSizeErrorTitle: {
        id: 'servicerequest.views.add-quote.Large File Size Error',
        defaultMessage: 'File Upload'
    },
    fileTypeValidationMessage: {
        id: 'servicerequest.views.add-quote.File Type Validation Error Message',
        defaultMessage: 'File types exe, jar, and zip are not allowed.'
    }
});
