import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'servicerequest.views.reply-message.What window?',
        defaultMessage: 'What window?'
    },
    message: {
        id: 'servicerequest.views.reply-message.Message',
        defaultMessage: 'Message'
    },
    reply: {
        id: 'servicerequest.views.reply-message.Reply',
        defaultMessage: 'Reply'
    },
    replyButton: {
        id: 'servicerequest.views.reply-message.button.Reply',
        defaultMessage: 'Reply'
    },
    cancel: {
        id: 'servicerequest.views.reply-message.button.Cancel',
        defaultMessage: 'Cancel'
    }
});
