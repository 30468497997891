import { defineMessages } from 'react-intl';

export default defineMessages({
    dashboard: {
        id: 'portal.views.home-page-layout.Dashboard',
        defaultMessage: 'Dashboard',
    },
    serviceRequests: {
        id: 'portal.views.home-page-layout.Service Requests',
        defaultMessage: 'Service Requests',
    },
    quoteRequests: {
        id: 'portal.views.home-page-layout.Quote Requests',
        defaultMessage: 'Quote Requests',
    },
    serviceRequestName: {
        id: 'portal.views.dashboard-table.service-request.Name',
        defaultMessage: 'Name',
    },
    serviceRequestNameAsc: {
        id: 'portal.views.dashboard-table.service-request.Name Ascending',
        defaultMessage: 'Name (Ascending)'
    },
    serviceRequestNameDesc: {
        id: 'portal.views.dashboard-table.service-request.Name Descending',
        defaultMessage: 'Name (Descending)'
    },
    serviceRequestClaimNumber: {
        id: 'portal.views.dashboard-table.service-request.Claim Number',
        defaultMessage: 'Claim Number',
    },
    serviceRequestNumber: {
        id: 'portal.views.dashboard-table.service-request.Service #',
        defaultMessage: 'Service #',
    },
    serviceRequestProgress: {
        id: 'portal.views.dashboard-table.service-request.Progress',
        defaultMessage: 'Progress',
    },
    serviceRequestRequestType: {
        id: 'portal.views.dashboard-table.service-request.Request Type',
        defaultMessage: 'Request Type',
    },
    serviceRequestDueDate: {
        id: 'portal.views.dashboard-table.service-request.Due Date',
        defaultMessage: 'Due Date',
    },
    serviceRequestServiceType: {
        id: 'portal.views.dashboard-table.service-request.Service Type',
        defaultMessage: 'Service Type',
    },
    serviceRequestProgressAcceptWork: {
        id: 'servicerequest.views.service-requests.progress.Accept Work',
        defaultMessage: 'Accept Work',
    },
    serviceRequestProgressDeclineWork: {
        id: 'servicerequest.views.service-requests.progress.Decline Work',
        defaultMessage: 'Decline Work',
    },
    serviceRequestProgressCompleteWork: {
        id: 'servicerequest.views.service-requests.progress.Complete Work',
        defaultMessage: 'Complete Work',
    },
    serviceRequestProgressCancelWork: {
        id: 'servicerequest.views.service-requests.progress.Cancel Work',
        defaultMessage: 'Cancel Work',
    },
    serviceRequestProgressAddInvoice: {
        id: 'servicerequest.views.service-requests.progress.Add Invoice',
        defaultMessage: 'Add Invoice',
    },
    serviceRequestProgressResumeWork: {
        id: 'servicerequest.views.service-requests.progress.Resume Work',
        defaultMessage: 'Resume Work',
    },
    serviceRequestProgressAddQuote: {
        id: 'servicerequest.views.service-requests.progress.Add Quote',
        defaultMessage: 'Add Quote',
    },
    searchNameOrClaim: {
        id: 'portal.views.dashboard-table.service-request.Search name or claim number',
        defaultMessage: 'Search name or claim number or service type...',
    }
});
