import React, { Fragment,useState, useCallback } from 'react';
import _, { isUndefined } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { Loader } from '@jutro/components';
import { useAuthentication } from 'gw-digital-auth-react';

import metadata from './EntryPage.metadata.json5';

const VIEWS = {
    logInView: 'LOG_IN_VIEW',
    forgotPasswordView: 'FORGOT_PASSWORD_VIEW'
};


const { applicationDetails, oobloginURL } = appConfig;

function EntryPage({ location }) {
    const { isAuthInProgress, isLoggedIn } = useAuthentication();
    const [view, updateView] = useState(VIEWS.logInView);
    const { state } = location;
    let disableOOTBLogin = false;

    const handleForgotPasswordClick = useCallback(() => {
        updateView(VIEWS.forgotPasswordView);
    }, []);

    const handleSignInClick = useCallback(() => {
        updateView(VIEWS.logInView);
    }, []);

    // will still allow local host to see the OOTB login page , assuming they are doing development
    // skip OOTB login page if not localhost:3000, assuming IDP is integrated

    let urls = _.find(oobloginURL, (value) => {
        return _.endsWith(window.location.origin, value.url);
    });



    if (urls === undefined || urls === null) {
        disableOOTBLogin = true;
    // return (<Fragment></Fragment>);
    }


    if (isAuthInProgress) {
        return <Loader loaded={!isAuthInProgress} />;
    }

    const overrideProps = {
        gwLoginComponent: {
            successPath: _.get(state, 'from.pathname') || '/'
        },
        logInViewContainer: {
            visible: view === VIEWS.logInView
        },
        forgotPasswordViewContainer: {
            visible: view === VIEWS.forgotPasswordView
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onForgotPasswordClick: handleForgotPasswordClick,
            onSignInClick: handleSignInClick
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
            disableOOTBLogin={disableOOTBLogin}
        />
    );
}

EntryPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({})
    })
};

EntryPage.defaultProps = {
    location: {}
};

export default EntryPage;
