import { defineMessages } from 'react-intl';

export default defineMessages({
    serviceRequestsDetails: {
        id: 'portal.views.home-page-layout.Requests Details',
        defaultMessage: 'Requests Details',
    },
    serviceRequestCustomerAddress: {
        id: 'portal.views.dashboard-table.service-request.Customer Address',
        defaultMessage: 'Customer Address',
    },
    serviceRequestCustomerContact: {
        id: 'portal.views.dashboard-table.service-request.Customer Contact',
        defaultMessage: 'Customer Contact',
    },
    serviceRequestClaimHandler: {
        id: 'portal.views.dashboard-table.service-request.Claim Handler',
        defaultMessage: 'Claim Handler',
    },
    serviceRequestClaimDetails: {
        id: 'portal.views.dashboard-table.service-request.Claim Details',
        defaultMessage: 'Claim Details',
    },
    serviceRequestClaim: {
        id: 'portal.views.dashboard-table.service-request.Claim',
        defaultMessage: 'Claim',
    },
    serviceRequestRequestDate: {
        id: 'portal.views.dashboard-table.service-request.Request Date',
        defaultMessage: 'Request Date',
    },
    serviceRequestServices: {
        id: 'portal.views.dashboard-table.service-request.Services',
        defaultMessage: 'Services',
    },
    serviceRequestNextStep: {
        id: 'portal.views.dashboard-table.service-request.Next Step',
        defaultMessage: 'Next Step',
    },
    serviceRequestInvoice: {
        id: 'portal.views.dashboard-table.service-request.Invoice',
        defaultMessage: 'Invoice',
    },
    serviceRequestPaymentMade: {
        id: 'portal.views.dashboard-table.service-request.Payment Made',
        defaultMessage: 'Payment Made',
    },
    actionMoreActions: {
        id: 'servicerequest.services.ServiceRequestService.action.More Actions',
        defaultMessage: 'More Actions'
    },
    specialistacceptedwork: {
        id: 'servicerequest.services.ServiceRequestService.action.Accept Work',
        defaultMessage: 'Accept Work'
    },
    specialistresumedwork: {
        id: 'servicerequest.services.ServiceRequestService.action.Resume Work',
        defaultMessage: 'Resume Work'
    },
    specialistdeclined: {
        id: 'servicerequest.services.ServiceRequestService.action.Decline Work',
        defaultMessage: 'Decline Work'
    },
    actionAgree: {
        id: 'servicerequest.services.ServiceRequestService.action.Agree',
        defaultMessage: 'Agree'
    },
    actionResume: {
        id: 'servicerequest.services.ServiceRequestService.action.Resume',
        defaultMessage: 'Resume'
    },
    actionDecline: {
        id: 'servicerequest.services.ServiceRequestService.action.Decline',
        defaultMessage: 'Decline'
    },
    actionReportWorkBlocked: {
        id: 'servicerequest.services.ServiceRequestService.action.Report : Work Blocked',
        defaultMessage: 'Report : Work Blocked'
    },
    specialistwaiting: {
        id: 'servicerequest.services.ServiceRequestService.action.Pause Work',
        defaultMessage: 'Pause Work'
    },
    specialistcompletedwork: {
        id: 'servicerequest.services.ServiceRequestService.action.Complete Work',
        defaultMessage: 'Complete Work'
    },
    specialistcanceled: {
        id: 'servicerequest.services.ServiceRequestService.action.Cancel Work',
        defaultMessage: 'Cancel Work'
    },
    actionFinish: {
        id: 'servicerequest.services.ServiceRequestService.action.Finish',
        defaultMessage: 'Finish'
    },
    actionCancel: {
        id: 'servicerequest.services.ServiceRequestService.action.Cancel',
        defaultMessage: 'Cancel '
    },
    actionButtonCancel: {
        id: 'servicerequest.views.buttons.Cancel',
        defaultMessage: 'Cancel'
    },
    actionAddQuote: {
        id: 'servicerequest.services.ServiceRequestService.action.Add Quote',
        defaultMessage: 'Add Quote'
    },
    actionReviseQuote: {
        id: 'servicerequest.services.ServiceRequestService.action.Revise Quote',
        defaultMessage: 'Revise Quote'
    },
    addinvoice: {
        id: 'servicerequest.services.ServiceRequestService.action.Add Invoice',
        defaultMessage: 'Add Invoice'
    },
    actionReportADelay: {
        id: 'servicerequest.services.ServiceRequestService.action.Report a Delay',
        defaultMessage: 'Report a Delay'
    },
    actionTitleCompleteWork: {
        id: 'servicerequest.views.complete-work.Complete Work',
        defaultMessage: 'Complete Work'
    },
    actionButtonCompleteWork: {
        id: 'servicerequest.views.complete-work.button.Complete',
        defaultMessage: 'Complete'
    },
    actionMessageCompleteWork: {
        id: 'servicerequest.views.complete-work.button.Are you sure you want to complete this service request?',
        defaultMessage: 'Are you sure you want to complete this service request?'
    },
    actionTitleDeclineWork: {
        id: 'servicerequest.views.decline-work.Decline Work',
        defaultMessage: 'Decline Work'
    },
    actionMessageDeclineWork: {
        id: 'servicerequest.views.decline-work.Reason',
        defaultMessage: 'Reason'
    },
    actionButtonDeclineWork: {
        id: 'servicerequest.views.decline-work.button.Decline Work',
        defaultMessage: 'Decline Work'
    },
    actionTitlePauseWork: {
        id: 'servicerequest.views.pause-work.Pause Work',
        defaultMessage: 'Pause Work'
    },
    actionMessagePauseWork: {
        id: 'servicerequest.views.pause-work.Reason',
        defaultMessage: 'Reason'
    },
    actionButtonPauseWork: {
        id: 'servicerequest.views.pause-work.button.Pause',
        defaultMessage: 'Pause'
    },
    actionAcceptFailed: {
        id: 'servicerequest.controllers.ServiceRequestWorkOperationCtrl.Accepting work failed',
        defaultMessage: 'Accepting work failed'
    },
    messages: {
        id: 'portal.views.dashboard-table.service-request.Messages',
        defaultMessage: 'Messages',
    },
    serviceRequestMessageFrom: {
        id: 'portal.views.dashboard-table.service-request.Message.From',
        defaultMessage: 'From',
    },
    serviceRequestMessageSubject: {
        id: 'portal.views.dashboard-table.service-request.Message.Subject message',
        defaultMessage: 'Subject message',
    },
    serviceRequestMessageMessage: {
        id: 'portal.views.dashboard-table.service-request.Message.Message',
        defaultMessage: 'Message',
    },
    serviceRequestMessageType: {
        id: 'portal.views.dashboard-table.service-request.Message.Type',
        defaultMessage: 'Type',
    },
    serviceRequestMessageDateSent: {
        id: 'portal.views.dashboard-table.service-request.Message.Date sent',
        defaultMessage: 'Date sent',
    },
    serviceRequestInvoices: {
        id: 'portal.views.dashboard-table.service-request.Invoices',
        defaultMessage: 'Invoices',
    },
    serviceRequestInvoiceReferenceNumber: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Reference number',
        defaultMessage: 'Reference number',
    },
    serviceRequestInvoiceDescription: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Description',
        defaultMessage: 'Description',
    },
    serviceRequestInvoiceSubmitted: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Submitted',
        defaultMessage: 'Submitted',
    },
    serviceRequestInvoiceStatus: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Status',
        defaultMessage: 'Status',
    },
    serviceRequestInvoiceTotalamount: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Total amount',
        defaultMessage: 'Total amount',
    },
    serviceRequestInvoicePaiddate: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Paid date',
        defaultMessage: 'Paid date',
    },
    serviceRequestWithdraw: {
        id: 'portal.views.dashboard-table.service-request.Invoices.Withdraw',
        defaultMessage: 'Withdraw',
    },
    serviceRequestHistory: {
        id: 'portal.views.dashboard-table.service-request.History',
        defaultMessage: 'History',
    },
    serviceRequestHistoryDate: {
        id: 'portal.views.dashboard-table.service-request.History.Date',
        defaultMessage: 'Date',
    },
    serviceRequestHistoryInitiator: {
        id: 'portal.views.dashboard-table.service-request.History.Initiator',
        defaultMessage: 'Initiator',
    },
    serviceRequestHistoryDescription: {
        id: 'portal.views.dashboard-table.service-request.History.Description',
        defaultMessage: 'Description',
    },
    serviceRequestDocuments: {
        id: 'portal.views.dashboard-table.service-request.Documents',
        defaultMessage: 'Documents',
    },
    serviceRequestDocumentsName: {
        id: 'portal.views.dashboard-table.service-request.Documents.Name',
        defaultMessage: 'Name',
    },
    serviceRequestDocumentsType: {
        id: 'portal.views.dashboard-table.service-request.Documents.Type',
        defaultMessage: 'Type',
    },
    serviceRequestDocumentsDateuploaded: {
        id: 'portal.views.dashboard-table.service-request.Documents.Date uploaded',
        defaultMessage: 'Date uploaded',
    },
    addInvoiceButton: {
        id: 'servicerequest.views.service-request-details.Add Invoice',
        defaultMessage: 'Add Invoice'
    },
    actionTitleCancelWork: {
        id: 'servicerequest.views.cancel-work.Cancel Work',
        defaultMessage: 'Cancel Work'
    },
    actionMessageCancelWork: {
        id: 'servicerequest.views.cancel-work.Reason',
        defaultMessage: 'Reason'
    },
    actionButtonCancelWork: {
        id: 'servicerequest.views.cancel-work.button.Cancel Work',
        defaultMessage: 'Cancel Work'
    },
    actionTitleResumeWork: {
        id: 'servicerequest.views.resume-work.Resume Work',
        defaultMessage: 'Resume Work'
    },
    actionMessageResumeWork: {
        id: 'servicerequest.views.resume-work.button.Are you sure you want to resume working on this service request?',
        defaultMessage: 'Are you sure you want to resume working on this service request?'
    },
    actionButtonResumeWork: {
        id: 'servicerequest.views.resume-work.button.Resume Work',
        defaultMessage: 'Resume Work'
    },
    addNewMessageButton: {
        id: 'servicerequest.views.service-request-details.New Message',
        defaultMessage: 'New Message'
    },
    back: {
        id: 'servicerequest.services.ServiceRequestService.Back',
        defaultMessage: 'Back'
    },
    fileUploadSite: {
        id: 'servicerequest.services.ServiceRequestService.Large File Size',
        defaultMessage: 'Upload files > {value}MB'
    },

    fileSizeErrorTitle: {
        id: 'servicerequest.services.ServiceRequestService.Large File Size Error',
        defaultMessage: 'File Upload'
    },
    fileSizeErrorMessage: {
        id: 'servicerequest.services.ServiceRequestService.Large File Size Error Message',
        defaultMessage: 'You are attempting to upload a file greater than {value1}MB in size. Please click the \'Upload files > {value2}MB\' option to upload this file '
    },
    toolTipsmMessage: {
        id: 'servicerequest.services.ServiceRequestService.Large File Upload Link Tooltips',
        defaultMessage: 'Large File Upload Link Tooltips Test'
    },
    fileFormatMessage: {
        id: 'servicerequest.services.ServiceRequestService.File Format',
        defaultMessage: 'Please upload file(s) in the format \'<\'{value1}_{value2}_{value3}_{value4}_0#\'>\'.xxx'
    },
    errorTitle: {
        id: 'servicerequest.services.ServiceRequestService.Document Upload Error',
        defaultMessage: 'Document Upload Error'
    },
    errorMessage: {
        id: 'servicerequest.services.ServiceRequestService.Document Upload Error Message',
        defaultMessage: 'Unable to Upload Document at This Time'
    },
    fileTypeValidationMessage: {
        id: 'servicerequest.services.ServiceRequestService.File Type Validation Error Message',
        defaultMessage: 'File types exe, jar, and zip are not allowed.'
    }
});
