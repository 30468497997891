import { defineMessages } from 'react-intl';

export default defineMessages({
    addMessageTitle: {
        id: 'servicerequest.views.create.new.message.title.New Message',
        defaultMessage: 'New Message'
    },
    questionType: {
        id: 'servicerequest.views.create.new.message.type.Question',
        defaultMessage: 'Question'
    },
    informationType: {
        id: 'servicerequest.views.create.new.message.type.Information',
        defaultMessage: 'Information'
    },
    messageSubject: {
        id: 'servicerequest.views.create.new.message.Subject Message',
        defaultMessage: 'Subject Message'
    },
    description: {
        id: 'servicerequest.views.create.new.message.Message',
        defaultMessage: 'Message'
    },
    cancel: {
        id: 'servicerequest.views.create.new.message.buttons.Cancel',
        defaultMessage: 'Cancel'
    },
    send: {
        id: 'servicerequest.views.create.new.message.button.Send',
        defaultMessage: 'Send'
    }
});
