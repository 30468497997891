import { defineMessages } from 'react-intl';

export default defineMessages({
    searchForTopic: {
        id: 'faq.directive.Search for a topic',
        defaultMessage: 'Search for a topic',
    },
    frequentlyAskedQuestions: {
        id: 'faq.directive.Frequently Asked Questions',
        defaultMessage: 'Frequently Asked Questions',
    },
    typeQuestion: {
        id: 'faq.directive.Start typing your question...',
        defaultMessage: 'Start typing your question...',
    },
    sections: {
        id: 'faq.directive.Sections',
        defaultMessage: 'Sections',
    },
    noMatchingError: {
        id: 'faq.directive.No matching results found',
        defaultMessage: 'No matching results found',
    },
    aboutThePortal: {
        id: 'faq.sections.About the Portal',
        defaultMessage: 'About the Portal',
    },
    vendorEngagePortal: {
        id: 'swif.faq.topics.vendor_engage_basics.question',
        defaultMessage: 'What is SWIF VendorEngage Portal?',
    },
    vendorEngagePortalLongAnswer: {
        id: 'swif.faq.topics.vendor_engage_basics.long',
        defaultMessage: 'SWIF VendorEngage portal provides a way for SWIF to interact with its vendors. The information that is shared with the vendor is limited to the information that they require to successfully fulfil a service request. The application enables vendors to accept and manage service requests including:<li>Creating quote (and re-quotes) for services</li><li>Adding documents and invoices to service requests</li><li>Sending messages to and receiving messages from a claim handler</li>',
    },
    findServiceRequest: {
        id: 'swif.faq.topics.service_requests_basics.question',
        defaultMessage: 'How do I find the service requests?',
    },
    findServiceRequestLongAnswer: {
        id: 'swif.faq.topics.service_requests_basics.long',
        defaultMessage: 'The Service Requests home page contains a list with your service requests and you can use the search and filter options to find a particular service request.'
    },
    serviceRequestWorkflow: {
        id: 'swif.faq.topics.service_requests_workflow.question',
        defaultMessage: 'What is a typical workflow for successful service request?',
    },
    serviceRequestWorkflowLongAnswer: {
        id: 'swif.faq.topics.service_requests_workflow.long',
        defaultMessage: 'A typical workflow for a successful service request is the following:<li>You are presented with a service request and accept the work.</li><li>You enter an estimate and provide a quote.</li><li>You wait for the quote approval from the insurer.</li><li>Upon approval, you perform the work, indicate that it is complete and submit an invoice.</li>',
    },
    serviceRequestQuote: {
        id: 'swif.faq.topics.service_requests_quote.question',
        defaultMessage: 'How do I add a quote for a service request?',
    },
    serviceRequestQuoteLongAnswer: {
        id: 'swif.faq.topics.service_requests_quote.long',
        defaultMessage: 'You can add a quote from the Service Request Details page or from the Service Requests home page. You specify details such as total amount, total work days needed and so on. Every quote must have at least one document associated with it. You can either upload new documents or associate documents that already exist on the service request with a quote.',
    },
    alternativeActions: {
        id: 'swif.faq.topics.alternative_actions.question',
        defaultMessage: 'What alternative actions can I take?',
    },
    alternativeActionsLongAnswer: {
        id: 'swif.faq.topics.alternative_actions.long',
        defaultMessage: 'For a particular service request, you can only take suggested actions from the Service Requests home page. However, additional actions are available from the Service Request Details page. These actions include:<li>Decline Work - You can choose not to accept a service request that has been submitted to you.</li><li>Cancel Work - If you are unable to complete a service request that you have accepted, you can cancel it.</li><li>Report Delay - If you plan to finish work, but you are unable to complete it by the date indicated, you need to provide an updated date.</li><li>Report Work Blocked - If you plan to finish work, but you are unable to complete it by the date indicated and you cannot forecast when you will be able to compete it, you need to report that the work is blocked.</li>'
    },
    resumeReportedWork: {
        id: 'swif.faq.topics.resume_reported_work.question',
        defaultMessage: 'Can I resume work which was reported as work blocked by me?',
    },
    resumeReportedWorkLongAnswer: {
        id: 'swif.faq.topics.resume_reported_work.long',
        defaultMessage: 'This suggested action becomes an option only after you have selected the alternative action Report Work Blocked.'
    },
    exchangeMessages: {
        id: 'swif.faq.topics.exchange_messages.question',
        defaultMessage: 'How can I exchange messages with SWIF claim handler for a service request?',
    },
    exchangeMessagesLongAnswer: {
        id: 'swif.faq.topics.exchange_messages.long',
        defaultMessage: 'The Messages section displays messages sent between you as a vendor and SWIF claim handler that are related to the particular service request. You create new messages and replies from this tab. You can create messages of type Information or Question. A Question type message generates an activity for SWIF claim handler.',
    },
    customerCareNumber: {
        id: 'swif.faq.topics.customer_care.question',
        defaultMessage: 'Who can I reach out for questions?',
    },
    customerCareNumberLongAnswer: {
        id: 'swif.faq.topics.customer_care.long',
        defaultMessage: 'Please contact Customer Service at 570-963-4635 Monday through Friday 8AM to 4PM.',
    }
});
