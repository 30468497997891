import { defineMessages } from 'react-intl';

export default defineMessages({
    withdrawInvoiceTitle: {
        id: 'servicerequest.views.withdraw-invoice.title.Withdraw Invoice',
        defaultMessage: 'Withdraw Invoice'
    },
    reasonWithdrawal: {
        id: 'servicerequest.views.withdraw-invoice.Reason for withdrawal',
        defaultMessage: 'Reason for withdrawal'
    },
    cancel: {
        id: 'servicerequest.views.withdraw-invoice.buttons.Cancel',
        defaultMessage: 'Cancel'
    },
    withdrawButton: {
        id: 'servicerequest.views.withdraw-invoice.button.Withdraw',
        defaultMessage: 'Withdraw'
    }
});
